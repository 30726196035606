<template>
	<cms-multi-section value="content"/>
</template>

<script>
import CmsMultiSection from '~/cms/front/components/CmsMultiSection'
import { pageMixin } from '~/website/front/core/pages/page.mixin'

export default {
	components: {
		CmsMultiSection
	},
	mixins: [
		pageMixin
	],
	async mounted () {
		if (this.$app.page.route.path === '/') {
			if (this.$app.authorization.isAuthorizated) {
				await this.$app.authorization.fetchUser()
				const service = await this.$app.getService('rext')
				await service.klaviyoCreateHomePageEvent({ email: this.$app.authorization.client.user.email })
			}
		}
	}
}
</script>
